import React, { useState, useEffect, useRef } from 'react';
import PredictionsTab from './PredictionsTab'; // Adjust the path as needed
import InjuriesTab from './InjuriesTab';
import { allLeagues } from './leagues';
import { format } from 'date-fns';
import { 
  Select, MenuItem, FormControl, InputLabel,
  Card, CardContent, CardHeader,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography, Button, Box, LinearProgress,
  Popover, Tabs, Tab, CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import { marked } from 'marked';

const API_ENDPOINT = 'https://dam1un14l8.execute-api.eu-west-2.amazonaws.com/default/analysisBackend';

const ColorBar = ({ value }) => (
    <Box sx={{ 
        width: '100%', 
        mr: 1, 
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 2  // Add spacing between bar and percentage
    }}>
        <Box sx={{ flex: 1 }}>  {/* Make the progress bar take remaining space */}
            <LinearProgress 
                variant="determinate" 
                value={value * 100} 
                sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                        backgroundImage: `linear-gradient(90deg, 
                            ${value < 0.2 ? '#ff9800' : 
                            value < 0.4 ? '#4caf50' : 
                            value < 0.6 ? '#2196f3' : 
                            value < 0.8 ? '#9c27b0' : 
                            '#1976d2'} 0%, 
                            ${value < 0.2 ? '#f57c00' : 
                            value < 0.4 ? '#388e3c' : 
                            value < 0.6 ? '#1976d2' : 
                            value < 0.8 ? '#7b1fa2' : 
                            '#1565c0'} 100%)`,
                        transition: 'transform .6s ease-in-out',
                        transform: 'translateX(0)',
                    },
                }}
            />
        </Box>
        <Typography
            variant="caption"
            sx={{
                minWidth: '45px',  // Fixed width for percentage
                textAlign: 'right',
                color: 'text.secondary',
                fontWeight: '500',
            }}
        >
            {`${(value * 100).toFixed(0)}%`}
        </Typography>
    </Box>
);

const PredictedScore = ({ predictedScore }) => (
    <Box sx={{ 
        fontWeight: 'bold',
        fontSize: '2rem',
        color: 'black',
        textAlign: 'center',
        padding: '10px 20px',
        background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
        }
    }}>
        {predictedScore}
        <Typography 
            variant="body2" 
            sx={{ 
                marginTop: '0.2rem', 
                fontSize: '0.9rem', 
                fontStyle: 'italic',
                color: 'text.secondary',
                letterSpacing: '0.5px'
            }}
        >
            Predicted Goals
        </Typography>
    </Box>
);

const TeamGoalStats = ({ team, isHome }) => (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        width: '100%'
    }}>
        <Box sx={{ 
            textAlign: 'center', 
            mb: 1,
            backgroundColor: 'rgba(0,0,0,0.04)',
            py: 1,
            borderRadius: '8px'
        }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                {team.team_name} - {team.team_goal_stats.total_games_played} Games Played
            </Typography>
        </Box>
        <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)', 
            gap: 2
        }}>
            <Box sx={{ 
                bgcolor: 'rgba(33, 150, 243, 0.1)', 
                p: 2, 
                borderRadius: 2, 
                textAlign: 'center' 
            }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2196f3' }}>
                    {team.team_goal_stats.goals_scored}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Goals Scored
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5, fontWeight: 'medium' }}>
                    {(team.team_goal_stats.goals_scored / team.team_goal_stats.total_games_played).toFixed(1)} per game
                </Typography>
            </Box>
            <Box sx={{ 
                bgcolor: 'rgba(244, 67, 54, 0.1)', 
                p: 2, 
                borderRadius: 2, 
                textAlign: 'center' 
            }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#f44336' }}>
                    {team.team_goal_stats.goals_conceded}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Goals Conceded
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5, fontWeight: 'medium' }}>
                    {(team.team_goal_stats.goals_conceded / team.team_goal_stats.total_games_played).toFixed(1)} per game
                </Typography>
            </Box>
            <Box sx={{ 
                bgcolor: 'rgba(76, 175, 80, 0.1)', 
                p: 2, 
                borderRadius: 2, 
                textAlign: 'center' 
            }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                    {team.team_goal_stats.games_scored}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Games Scored In
                </Typography>
                <Box sx={{ mt: 1, width: '100%', bgcolor: 'rgba(0, 0, 0, 0.1)', height: 4, borderRadius: 2 }}>
                    <Box sx={{ 
                        width: `${(team.team_goal_stats.games_scored / team.team_goal_stats.total_games_played) * 100}%`, 
                        bgcolor: '#4caf50', 
                        height: '100%', 
                        borderRadius: 2 
                    }} />
                </Box>
                <Typography variant="body2" sx={{ mt: 0.5, textAlign: 'right', fontWeight: 'medium' }}>
                    {((team.team_goal_stats.games_scored / team.team_goal_stats.total_games_played) * 100).toFixed(0)}%
                </Typography>
            </Box>
            <Box sx={{ 
                bgcolor: 'rgba(156, 39, 176, 0.1)', 
                p: 2, 
                borderRadius: 2, 
                textAlign: 'center' 
            }}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#9c27b0' }}>
                    {team.team_goal_stats.cleansheets}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Clean Sheets
                </Typography>
                <Box sx={{ mt: 1, width: '100%', bgcolor: 'rgba(0, 0, 0, 0.1)', height: 4, borderRadius: 2 }}>
                    <Box sx={{ 
                        width: `${(team.team_goal_stats.cleansheets / team.team_goal_stats.total_games_played) * 100}%`, 
                        bgcolor: '#9c27b0', 
                        height: '100%', 
                        borderRadius: 2 
                    }} />
                </Box>
                <Typography variant="body2" sx={{ mt: 0.5, textAlign: 'right', fontWeight: 'medium' }}>
                    {((team.team_goal_stats.cleansheets / team.team_goal_stats.total_games_played) * 100).toFixed(0)}%
                </Typography>
            </Box>
        </Box>
    </Box>
);


const TeamInfo = ({ team, isHome }) => (
    <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: isHome ? 'flex-end' : 'flex-start', 
        flexDirection: 'column',
        transition: 'all 0.3s ease',
        padding: '10px',
        '&:hover': {
            transform: 'scale(1.02)',
        }
    }}>
        <Typography 
            variant="h6" 
            sx={{ 
                fontSize: '1.5rem', 
                fontWeight: 'bold', 
                textAlign: isHome ? 'right' : 'left',
                mb: 1,
                color: '#2c3e50'  // darker, richer color for team names
            }}
        >
            {team.team_name}
        </Typography>
        <Box sx={{
            position: 'relative',
            padding: '8px',
            borderRadius: '50%',
            background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        }}>
            <img 
                src={team.team_logo} 
                alt={`${team.team_name} logo`} 
                style={{ 
                    width: 48, 
                    height: 48,
                    transition: 'transform 0.3s ease',
                }} 
            />
        </Box>
        <Typography 
            variant="body2" 
            sx={{ 
                textAlign: isHome ? 'right' : 'left', 
                marginTop: '0.5rem', 
                fontSize: '1rem',
                fontWeight: '500',
                color: 'text.secondary',
                padding: '4px 12px',
                borderRadius: '8px',
                backgroundColor: 'rgba(0,0,0,0.04)',
            }}
        >
            {team.total_points} pts (Ansatz)
        </Typography>
    </Box>
);

const BetSuggestionPopup = ({ bestBet, anchorEl, onClose, fixtureId }) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={() => onClose(fixtureId)}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    PaperProps={{
      sx: {
        maxWidth: '350px',
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
        mt: 1.5,
        p: 0,
        borderRadius: '12px',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: '50%',
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg) translateX(-50%)',
          zIndex: 0,
        },
      }
    }}
  >
    {bestBet ? (
      <Box>
        <Box sx={{ 
          p: 2, 
          backgroundColor: '#1976d2', 
          color: 'white',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px'
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Best Bet Suggestion</Typography>
        </Box>
        
        <Box sx={{ p: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            mb: 1.5
          }}>
            <Box>
              <Typography variant="body2" color="text.secondary">Recommendation</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                {bestBet.recommendation}: <Box component="span" sx={{ fontWeight: 'bold' }}>{bestBet.value}</Box>
              </Typography>
            </Box>
            
            <Box sx={{ 
              px: 2, 
              py: 1, 
              bgcolor: 'rgba(25, 118, 210, 0.1)', 
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <Typography variant="body2" color="text.secondary">Odds</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                {bestBet.odds}
              </Typography>
            </Box>
          </Box>
          
          {bestBet.confidence && (
            <Box sx={{ mb: 1.5 }}>
              <Typography variant="body2" color="text.secondary">Confidence</Typography>
              <Box sx={{ 
                mt: 0.5,
                display: 'flex',
                alignItems: 'center'
              }}>
                <Box sx={{ 
                  width: 80, 
                  height: 6, 
                  borderRadius: 3,
                  bgcolor: 
                    bestBet.confidence === 'High' ? '#4caf50' : 
                    bestBet.confidence === 'Medium' ? '#ff9800' : 
                    '#f44336',
                  mr: 1
                }} />
                <Typography variant="body2" sx={{ 
                  fontWeight: 'medium',
                  color: 
                    bestBet.confidence === 'High' ? '#4caf50' : 
                    bestBet.confidence === 'Medium' ? '#ff9800' : 
                    '#f44336',
                }}>
                  {bestBet.confidence}
                </Typography>
              </Box>
            </Box>
          )}
          
          {bestBet.reasoning && (
            <Box>
              <Typography variant="body2" color="text.secondary">Reasoning</Typography>
              <Typography variant="body2" sx={{ 
                mt: 0.5,
                p: 1.5,
                bgcolor: 'rgba(0, 0, 0, 0.03)',
                borderRadius: '8px',
                fontStyle: 'italic'
              }}>
                "{bestBet.reasoning}"
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    ) : (
      <Box sx={{ p: 2 }}>
        <Typography>No bet suggestion available for this fixture.</Typography>
      </Box>
    )}
  </Popover>
);

const BTTSVisualization = ({ value }) => {
    // Round to 1 decimal place for display
    const displayValue = (value * 100).toFixed(1);
    //const colorClass = value > 0.6 ? 'high' : (value > 0.4 ? 'medium' : 'low');
    
    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
        }}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
                Both Teams to Score Probability
            </Typography>
            
            <Box sx={{ 
                position: 'relative',
                width: '100%',
                maxWidth: 300,
                margin: 'auto'
            }}>
                {/* Main gauge */}
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box sx={{
                        width: 140,
                        height: 140,
                        borderRadius: '50%',
                        background: `conic-gradient(
                            #2196f3 0% ${value * 100}%,
                            rgba(0, 0, 0, 0.1) ${value * 100}% 100%
                        )`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            width: 110,
                            height: 110,
                            borderRadius: '50%',
                            background: 'white',
                        }
                    }}>
                        <Typography 
                            variant="h5" 
                            sx={{ 
                                zIndex: 1, 
                                fontWeight: 'bold',
                                color: value > 0.6 ? '#1565c0' : (value > 0.4 ? '#0277bd' : '#0288d1')
                            }}
                        >
                            {displayValue}%
                        </Typography>
                    </Box>
                </Box>
                
                {/* Interpretation text */}
                <Typography 
                    variant="body2" 
                    align="center" 
                    sx={{ 
                        mt: 1.5,
                        color: value > 0.6 ? '#1565c0' : (value > 0.4 ? '#0277bd' : '#0288d1'),
                        fontWeight: 500
                    }}
                >
                    {value > 0.7 ? 'Very Likely' : 
                     value > 0.5 ? 'Likely' : 
                     value > 0.3 ? 'Possible' : 'Unlikely'}
                </Typography>
            </Box>
        </Box>
    );
};

const PerformanceDifferenceVisualization = ({ value, homeTeam, awayTeam }) => {
    // Calculate absolute value and percentage for display
    const absValue = Math.abs(value);
    const percentage = Math.min(Math.abs(value) * 100, 100).toFixed(1);
    const homeAdvantage = value > 0;
    
    // Determine which team has the advantage
    const advantageTeam = homeAdvantage ? homeTeam : awayTeam;
    const advantageSize = absValue > 0.5 ? 'significant' : (absValue > 0.2 ? 'moderate' : 'slight');
    
    return (
        <Box sx={{ width: '100%', my: 2 }}>
            <Typography variant="body2" sx={{ mb: 1, fontWeight: 500 }}>
                Performance Difference
            </Typography>
            
            {/* Team names */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" sx={{ fontWeight: homeAdvantage ? 'bold' : 'normal' }}>
                    {homeTeam.team_name}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: !homeAdvantage ? 'bold' : 'normal' }}>
                    {awayTeam.team_name}
                </Typography>
            </Box>
            
            {/* Tug of war visualization */}
            <Box sx={{ 
                position: 'relative', 
                height: 10, 
                bgcolor: 'rgba(0,0,0,0.05)',
                borderRadius: 5,
                overflow: 'hidden',
                mb: 1,
                border: '1px solid rgba(0,0,0,0.1)'
            }}>
                {/* Center marker */}
                <Box sx={{ 
                    position: 'absolute',
                    left: '50%',
                    top: 0,
                    bottom: 0,
                    width: 2,
                    bgcolor: 'rgba(0,0,0,0.2)',
                    zIndex: 1
                }} />
                
                {/* Team advantage bar */}
                <Box sx={{ 
                    position: 'absolute',
                    [homeAdvantage ? 'left' : 'right']: '50%',
                    top: 0,
                    bottom: 0,
                    width: `${Math.min(absValue * 100, 50)}%`,
                    bgcolor: homeAdvantage ? '#4caf50' : '#f44336',
                    transition: 'width 0.5s ease-out'
                }} />
            </Box>
            
            {/* Interpretation text */}
            <Typography 
                variant="body2" 
                align="center" 
                sx={{ 
                    color: homeAdvantage ? '#4caf50' : '#f44336',
                    fontWeight: 500
                }}
            >
                {advantageSize === 'significant' ? 'Significant ' : 
                 advantageSize === 'moderate' ? 'Moderate ' : 'Slight '} 
                advantage for {advantageTeam.team_name} ({percentage}%)
            </Typography>
            
            {/* Value display */}
            <Typography variant="caption" align="center" sx={{ display: 'block', color: 'text.secondary' }}>
                Raw difference: {value.toFixed(2)}
            </Typography>
        </Box>
    );
};

const AnalysisPopup = ({ analysis, status, error, anchorEl, onClose }) => ( // Add status and error props
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    PaperProps={{
      sx: {
        maxWidth: '80vw',
        maxHeight: '80vh',
        minWidth: 300,
        p: 3,
        overflowY: 'auto',
        background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
        backdropFilter: 'blur(10px)',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
      }
    }}
  >
    <Box>
      {status === 'initiating' && ( // Show initiating state
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, py: 3 }}>
          <CircularProgress />
          <Typography>Starting analysis...</Typography>
        </Box>
      )}
      {status === 'polling' && ( // Show polling state
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, py: 3 }}>
          <CircularProgress />
          <Typography>Processing analysis...</Typography>
          <Typography variant="body2" color="text.secondary">(Analyzing fixture, please wait ...)</Typography>
        </Box>
      )}
      {status === 'completed' && analysis && ( // Show completed analysis
        <Typography component="div"
          dangerouslySetInnerHTML={{
            __html: marked.parse(analysis)
          }}
        />
      )}
       {status === 'completed' && !analysis && ( // Handle case where status is complete but analysis is missing
           <Typography color="error">Analysis complete, but no content received.</Typography>
       )}
      {status === 'failed' && ( // Show error state
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, py: 3, color: 'error.main' }}>
           <ErrorOutline />
           <Typography sx={{ fontWeight: 'bold' }}>Analysis Failed</Typography>
           <Typography variant="body2" sx={{ textAlign: 'center' }}>{error || 'An unexpected error occurred.'}</Typography>
        </Box>
      )}
       {/* Add a fallback for the 'idle' state or if status is unexpected */}
       {status === 'idle' && (
            <Typography>Click the button to start the analysis.</Typography>
       )}
    </Box>
  </Popover>
);

export default function FootballFixtures() {
    const [selectionState, setSelectionState] = useState({
      country: "England",
      league: "Premier League",
    });

    const [fixturesState, setFixturesState] = useState({
      data: [],
      expandedFixture: null,
      isLoading: false,
      error: null,
    });

    const [uiState, setUiState] = useState({
      betAnchors: {},
      activeTab: 0,
      analysisAnchor: null,
    });

    const [analysisState, setAnalysisState] = useState({
      data: null,
      status: 'idle', // 'idle', 'initiating', 'polling', 'completed', 'failed'
      jobId: null,
      error: null,
    });    


    // Add this after your state declarations
    const pollingTimerRef = useRef(null);

    const clearPollingTimer = () => {
      if (pollingTimerRef.current) {
        clearTimeout(pollingTimerRef.current);
        pollingTimerRef.current = null;
      }
    };

    useEffect(() => {
      fetchFixtures(selectionState.country, selectionState.league);
      
      // Cleanup polling timer if component unmounts
      return () => {
        if (pollingTimerRef.current) {
          clearTimeout(pollingTimerRef.current);
        }
      };        
    }, [selectionState.country, selectionState.league]);

    // Replace the second useEffect with this
    useEffect(() => {
      // If the analysis popup is closed or the fixture is collapsed, stop polling
      if (!uiState.analysisAnchor || fixturesState.expandedFixture === null) {
        clearPollingTimer();
        setAnalysisState(prev => ({
          ...prev,
          status: 'idle',
          jobId: null
        }));
      }
      
      // Cleanup function for this effect instance
      return () => {
        clearPollingTimer();
      };
    }, [uiState.analysisAnchor, fixturesState.expandedFixture]);

    const fetchFixtures = async (country, league) => {
      setFixturesState(prev => ({
        ...prev,
        isLoading: true,
        error: null,
      }));
      
      try {
        const response = await fetch(`${API_ENDPOINT}?country=${country}&league=${league}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data && Array.isArray(data.items)) {
          setFixturesState(prev => ({
            ...prev,
            data: data.items,
            isLoading: false,
          }));
        } else {
          console.error('Unexpected data format:', data);
          setFixturesState(prev => ({
            ...prev,
            data: [],
            isLoading: false,
            error: 'Received unexpected data format from the server.',
          }));
        }
      } catch (error) {
        console.error('Error fetching fixtures:', error);
        setFixturesState(prev => ({
          ...prev,
          data: [],
          isLoading: false,
          error: 'Failed to fetch fixtures. Please try again later.',
        }));
      }
    };

    const handleCountryChange = (country) => {
      setSelectionState(prev => ({
        ...prev,
        country,
        league: allLeagues[country][0].name,
      }));
    };

    const handleLeagueChange = (league) => {
      setSelectionState(prev => ({
        ...prev,
        league,
      }));
    };

    const toggleFixtureExpansion = (fixtureId) => {
      setFixturesState(prev => ({
        ...prev,
        expandedFixture: prev.expandedFixture === fixtureId ? null : fixtureId,
      }));
      // Close analysis popup when collapsing
      setUiState(prev => ({
        ...prev,
        analysisAnchor: null,
      }));
    };

    const calculateBTTS = (home, away) => {
        return ((home?.probability_to_score || 0) * (away?.probability_to_score || 0));
    };

    const calculatePerformanceDifference = (home, away) => {
        return ((home?.home_performance || 0) - (away?.away_performance || 0));
    };

    const handleBetInfoClick = (event, fixtureId) => {
      event.stopPropagation();
      setUiState(prev => ({
        ...prev,
        betAnchors: {
          ...prev.betAnchors,
          [fixtureId]: event.currentTarget
        }
      }));
    };

    const handleBetInfoClose = (fixtureId) => {
      setUiState(prev => {
        const newAnchors = {...prev.betAnchors};
        delete newAnchors[fixtureId];
        return {
          ...prev,
          betAnchors: newAnchors
        };
      });
    };

    const handleTabChange = (event, newValue) => {
      setUiState(prev => ({
        ...prev,
        activeTab: newValue
      }));
    };

    const renderPastFixtures = (fixtures, teamName = null) => {
        if (!fixtures || fixtures.length === 0) {
            return <Typography>No past fixtures available</Typography>;
        }

        const getScoreColor = (homeScore, awayScore, homeTeam, awayTeam) => {
            if (!teamName) return 'grey.500'; // For head to head, use grey

            if (teamName === homeTeam) {
                if (homeScore > awayScore) return 'success.main';
                if (homeScore < awayScore) return 'error.main';
                return 'grey.500';
            } else if (teamName === awayTeam) {
                if (awayScore > homeScore) return 'success.main';
                if (awayScore < homeScore) return 'error.main';
                return 'grey.500';
            }
            return 'grey.500'; // Default case
        };  

        return (
            <Box sx={{ width: '100%', overflowX: 'auto' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Home</TableCell>
                            <TableCell>Score</TableCell>
                            <TableCell>Away</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fixtures.map((fixture, index) => (
                            <TableRow key={index}>
                                <TableCell>{format(new Date(fixture.date), 'dd MMM yyyy')}</TableCell>
                                <TableCell>{fixture.teams.home}</TableCell>
                                <TableCell>
                                    <Box
                                        sx={{
                                            backgroundColor: getScoreColor(fixture.scores.home, fixture.scores.away, fixture.teams.home, fixture.teams.away),
                                            color: 'white',
                                            padding: '2px 6px',
                                            borderRadius: '4px',
                                            display: 'inline-block'
                                        }}
                                    >
                                        {fixture.scores.home} - {fixture.scores.away}
                                    </Box>
                                </TableCell>
                                <TableCell>{fixture.teams.away}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };


    const fetchAnalysis = async (fixtureId, event) => {
      event.stopPropagation();

      // Prevent starting a new request if one is already in progress
      if (analysisState.status !== 'idle' && analysisState.status !== 'completed' && analysisState.status !== 'failed') {
        console.log("Analysis already in progress.");
        setUiState(prev => ({
          ...prev,
          analysisAnchor: event.currentTarget
        }));
        return;
      }

      // Set initial states
      setUiState(prev => ({
        ...prev,
        analysisAnchor: event.currentTarget
      }));
      
      setAnalysisState(prev => ({
        ...prev,
        data: null,
        status: 'initiating', 
        error: null
      }));

      // Start the polling process
      pollAnalysis(fixtureId);
    };


    const pollAnalysis = async (fixtureId) => {
      // Clear any existing timer before setting a new one
      clearPollingTimer();

      const maxAttempts = 20; // Stop polling after ~5 minutes (20 attempts * 15 seconds)
      const pollingInterval = 15000; // Poll every 15 seconds
      
      let attempts = 0;

      const poll = async () => {
        if (attempts >= maxAttempts) {
          console.error(`Polling stopped after ${maxAttempts} attempts.`);
          setAnalysisState(prev => ({
            ...prev,
            status: 'failed',
            error: "Analysis is taking longer than expected. Please try again later."
          }));
          return;
        }

        attempts++;
        console.log(`Polling analysis... Attempt ${attempts}`);

        // AbortController for request timeout
        const controller = new AbortController();
        const timeoutDuration = 30000; // 30 seconds timeout
        const timeoutId = setTimeout(() => {
          console.warn(`Request timed out after ${timeoutDuration / 1000} seconds.`);
          controller.abort();
        }, timeoutDuration);

        try {
          // Always use the same endpoint with the fixture_id
          const response = await fetch('https://4m1gy2s99a.execute-api.eu-west-2.amazonaws.com/default/genai-pundit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fixture_id: fixtureId }),
            signal: controller.signal
          });

          clearTimeout(timeoutId);

          // Handle different response statuses
          if (response.status === 202) {
            // Analysis not ready yet, schedule another poll
            setAnalysisState(prev => ({
              ...prev,
              status: 'polling'
            }));
            pollingTimerRef.current = setTimeout(poll, pollingInterval);
          } else if (response.ok) {
            // Analysis is ready, process the result
            const data = await response.json();
            if (data && data.analysis) {
              setAnalysisState(prev => ({
                ...prev,
                data: data.analysis,
                status: 'completed'
              }));
              console.log("Analysis received successfully");
            } else {
              throw new Error("Response received but no analysis data found");
            }
          } else {
            // Handle error responses
            const errorText = await response.text();
            throw new Error(`Request failed: ${response.status} - ${errorText}`);
          }
        } catch (error) {
          clearTimeout(timeoutId);
          console.error(`Polling attempt ${attempts} failed:`, error);
          
          // Handle different error types
          if (error.name === 'AbortError') {
            setAnalysisState(prev => ({
              ...prev,
              error: `Request timed out. Retrying...`
            }));
          } else {
            setAnalysisState(prev => ({
              ...prev,
              error: `Error fetching analysis: ${error.message}`
            }));
          }
          
          // Continue polling if under max attempts
          if (attempts < maxAttempts) {
            pollingTimerRef.current = setTimeout(poll, pollingInterval);
          } else {
            setAnalysisState(prev => ({
              ...prev,
              status: 'failed'
            }));
          }
        }
      };

      // Start the first poll attempt immediately
      poll();
    };

    const renderNextFixture = (team) => {
        if (!team.Next_Fix_Date || !team.Next_Fix_Type || !team.Next_League || !team.Next_Opp) {
            return <Typography>No upcoming fixture information available</Typography>;
        }

        const nextFixDate = new Date(team.Next_Fix_Date);
        const formattedDate = format(nextFixDate, 'dd MMMM yyyy HH:mm');
        const isHome = team.Next_Fix_Type.toLowerCase() === 'home';

        return (
            <Box sx={{ 
                p: 2, 
                borderRadius: '16px',
                background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
                }
            }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, color: '#1976d2' }}>
                    {team.team_name}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    <strong>Date:</strong> {formattedDate}
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    <strong>{isHome ? 'Home' : 'Away'}</strong> vs {team.Next_Opp}
                </Typography>
                <Typography>
                    <strong>League:</strong> {team.Next_League}
                </Typography>
            </Box>
        );
    };

    return (
        <Box sx={{ 
            maxWidth: 1200, 
            margin: 'auto', 
            padding: 2,
            minHeight: '100vh',
            background: 'linear-gradient(145deg, #f0f2f5 0%, #ffffff 100%)',
            backgroundImage: `linear-gradient(30deg, rgba(53, 162, 235, 0.03) 12%, transparent 12.5%, transparent 87%, rgba(53, 162, 235, 0.03) 87.5%, rgba(53, 162, 235, 0.03))`,
            backgroundSize: '4em 4em',
        }}>
            <Typography variant="h4" component="h1" sx={{
                fontWeight: 700,
                color: '#1a237e',
                textAlign: 'center',
                mb: 4,
                padding: '20px 0',
                borderBottom: '2px solid rgba(0,0,0,0.1)',
            }}>
                Football Fixtures
            </Typography>
            
            <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                mb: 4,
                backgroundColor: 'white',
                padding: 3,
                borderRadius: '12px',
                boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            }}>
                <FormControl fullWidth>
                    <InputLabel id="country-select-label">Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        value={selectionState.country}
                        label="Country"
                        onChange={(e) => handleCountryChange(e.target.value)}
                    >
                        {Object.keys(allLeagues).map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                <FormControl fullWidth>
                    <InputLabel id="league-select-label">League</InputLabel>
                    <Select
                        labelId="league-select-label"
                        value={selectionState.league}
                        label="League"
                        onChange={(e) => handleLeagueChange(e.target.value)}
                    >
                        {allLeagues[selectionState.country].map((league) => (
                            <MenuItem key={league.id} value={league.name}>
                                {league.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {fixturesState.isLoading && <LinearProgress />}
            {fixturesState.error && (
                <Typography color="error" sx={{ mb: 2 }}>
                    {fixturesState.error}
                </Typography>
            )}
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {Array.isArray(fixturesState.data) && fixturesState.data.length > 0 ? fixturesState.data.map((fixture) => (
                    <Card 
                        key={fixture.fixture_id} 
                        sx={{
                            borderRadius: '12px',
                            transition: 'all 0.3s ease',
                            background: 'white',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                            },
                            border: '1px solid rgba(0,0,0,0.08)',
                            '& .MuiCollapse-root': {
                                transition: 'all 0.3s ease-in-out',
                            }
                        }}
                    >
                        <CardHeader
                            sx={{ cursor: 'pointer' }}
                            onClick={() => toggleFixtureExpansion(fixture.fixture_id)}
                            title={
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <TeamInfo team={fixture.home} isHome={true} />
                                    </Box>
                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <PredictedScore predictedScore={`${fixture.home.predicted_goals} - ${fixture.away.predicted_goals}`} />
                                        {fixture.best_bet && fixture.best_bet.length > 0 && (
                                            <Button
                                                onClick={(e) => handleBetInfoClick(e, fixture.fixture_id)}
                                                sx={{ minWidth: 'auto', p: 0.5 }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </Button>
                                        )}
                                    </Box>
                                    <Box sx={{ flex: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <TeamInfo team={fixture.away} isHome={false} />
                                    </Box>
                                </Box>
                            }
                            action={
                                <Button onClick={() => toggleFixtureExpansion(fixture.fixture_id)}>
                                    {fixturesState.expandedFixture === fixture.fixture_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            }
                        />
                        {fixture.best_bet && fixture.best_bet.length > 0 && (
                            <BetSuggestionPopup
                                bestBet={fixture.best_bet[0]}
                                anchorEl={uiState.betAnchors[fixture.fixture_id]}
                                onClose={handleBetInfoClose}
                                fixtureId={fixture.fixture_id}
                            />
                        )}
                        {fixturesState.expandedFixture === fixture.fixture_id && (
                            <CardContent>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Date:</strong> {format(new Date(fixture.date), 'dd MMMM yyyy HH:mm')}
                                </Typography>
                                <Tabs 
                                    value={uiState.activeTab} 
                                    onChange={handleTabChange} 
                                    sx={{ 
                                        mb: 2,
                                        '& .MuiTabs-indicator': {
                                            height: 3,
                                            borderRadius: '3px 3px 0 0',
                                            backgroundColor: '#1976d2',
                                        },
                                        '& .MuiTab-root': {
                                            transition: 'all 0.2s ease',
                                            '&:hover': {
                                                color: '#1976d2',
                                                opacity: 1,
                                            },
                                            '&.Mui-selected': {
                                                color: '#1976d2',
                                                fontWeight: 'bold',
                                            }
                                        }
                                    }}
                                >
                                    <Tab 
                                        label="Statistics" 
                                        sx={{ 
                                            textTransform: 'none',
                                            fontWeight: 500,
                                        }} 
                                    />
                                    <Tab 
                                        label="Injuries" 
                                        sx={{ 
                                            textTransform: 'none',
                                            fontWeight: 500,
                                        }} 
                                    />
                                    <Tab 
                                        label="Last 5 Fixtures" 
                                        sx={{ 
                                            textTransform: 'none',
                                            fontWeight: 500,
                                        }} 
                                    />
                                    <Tab 
                                        label="Head to Head" 
                                        sx={{ 
                                            textTransform: 'none',
                                            fontWeight: 500,
                                        }} 
                                    />
                                    <Tab 
                                        label="Upcoming Fixtures" 
                                        sx={{ 
                                            textTransform: 'none',
                                            fontWeight: 500,
                                        }} 
                                    />
                                    <Tab 
                                        label="Predictions" 
                                        sx={{ 
                                            textTransform: 'none',
                                            fontWeight: 500,
                                        }} 
                                    />
                                </Tabs>
                                {uiState.activeTab === 0 && (
                                    <Box>
                                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="h6" gutterBottom>{fixture.home.team_name}</Typography>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Home Performance: {(fixture.home.home_performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.home_performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Overall Performance: {(fixture.home.performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Probability to Score: {(fixture.home.probability_to_score * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.probability_to_score} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Likelihood of Predicted Goals: {(fixture.home.likelihood * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.home.likelihood} />
                                                </Box>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="h6" gutterBottom>{fixture.away.team_name}</Typography>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Away Performance: {(fixture.away.away_performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.away_performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Overall Performance: {(fixture.away.performance * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.performance} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Probability to Score: {(fixture.away.probability_to_score * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.probability_to_score} />
                                                </Box>
                                                <Box sx={{ mb: 1 }}>
                                                    <Typography variant="body2">Likelihood of Predicted Goals: {(fixture.away.likelihood * 100).toFixed(2)}%</Typography>
                                                    <ColorBar value={fixture.away.likelihood} />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 4, mb: 4 }}>
                                            <Typography variant="h6" gutterBottom sx={{ 
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                pb: 1,
                                                mb: 2
                                            }}>
                                                Goal Statistics
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: 2 }}>
                                                <Box sx={{ flex: 1 }}>
                                                    <TeamGoalStats team={fixture.home} isHome={true} />
                                                </Box>
                                                <Box sx={{ flex: 1 }}>
                                                    <TeamGoalStats team={fixture.away} isHome={false} />
                                                </Box>
                                            </Box>
                                        </Box>                                        
                                        <Box sx={{ mb: 3 }}>
                                            <BTTSVisualization value={calculateBTTS(fixture.home, fixture.away)} />
                                        </Box>
                                        <Box sx={{ mb: 3 }}>
                                            <PerformanceDifferenceVisualization 
                                                value={calculatePerformanceDifference(fixture.home, fixture.away)} 
                                                homeTeam={fixture.home}
                                                awayTeam={fixture.away}
                                            />
                                        </Box>
                                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                variant="contained"
                                                onClick={(e) => fetchAnalysis(fixture.fixture_id, e)}
                                                // Disable button while initiating or polling
                                                disabled={analysisState.status === 'initiating' || analysisState.status === 'polling'}
                                                sx={{ minWidth: 150 }} // Give button more space for text
                                            >
                                                {analysisState.status === 'initiating' ? 'Starting...' :
                                                 analysisState.status === 'polling' ? <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} /> :
                                                 analysisState.status === 'completed' ? 'Show Analysis' : // Or 'Re-analyze'
                                                 analysisState.status === 'failed' ? 'Retry Analysis' :
                                                 'Get AI Analysis'}
                                                 {analysisState.status === 'polling' && 'Processing...'}
                                            </Button>
                                        </Box>

                                        <AnalysisPopup
                                            analysis={analysisState.data}
                                            status={analysisState.status} // Pass the status
                                            error={analysisState.error}   // Pass the error
                                            anchorEl={uiState.analysisAnchor}
                                            onClose={() => {
                                                setUiState(prev => ({
                                                    ...prev,
                                                    analysisAnchor: null
                                                }));
                                            }}
                                        />
                                    </Box>
                                )}
                                {uiState.activeTab === 1 && (
                                    <Box>
                                        <InjuriesTab 
                                            homeTeam={fixture.home}
                                            awayTeam={fixture.away}
                                        />
                                    </Box>
                                )}
                                {uiState.activeTab === 2 && (
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h6" gutterBottom>Last 5 Fixtures - {fixture.home.team_name}</Typography>
                                            {renderPastFixtures(fixture.home.past_fixtures, fixture.home.team_name)}
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography variant="h6" gutterBottom>Last 5 Fixtures - {fixture.away.team_name}</Typography>
                                            {renderPastFixtures(fixture.away.past_fixtures, fixture.away.team_name)}
                                        </Box>
                                    </Box>
                                )}
                                {uiState.activeTab === 3 && (
                                    <Box>
                                        <Typography variant="h6" gutterBottom>Head to Head</Typography>
                                        {renderPastFixtures(fixture.h2h)}
                                    </Box>
                                )}
                                {uiState.activeTab === 4 && (
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box sx={{ flex: 1 }}>
                                            {renderNextFixture(fixture.home)}
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            {renderNextFixture(fixture.away)}
                                        </Box>
                                    </Box>
                                )}
                                {uiState.activeTab === 5 && (
                                    <Box>
                                        <PredictionsTab 
                                            predictions={fixture.predictions} 
                                            homeTeam={fixture.home.team_name}
                                            awayTeam={fixture.away.team_name}
                                        />
                                    </Box>
                                )}                                
                            </CardContent>
                        )}
                    </Card>
                )) : (
                    <Typography>No fixtures available</Typography>
                )}
            </Box>
        </Box>
    );
}
