export const allLeagues = {
    "Austria": [
        {
            "id": 218,
            "name": "Bundesliga",
            "type": "League"
        },
        {
            "id": 219,
            "name": "2. Liga",
            "type": "League"
        }
    ],
    "Belgium": [
        {
            "id": 144,
            "name": "Jupiler Pro League",
            "type": "League"
        },
        {
            "id": 145,
            "name": "Challenger Pro League",
            "type": "League"
        }
    ],
    "Croatia": [
        {
            "id": 210,
            "name": "HNL",
            "type": "League"
        }
    ],
    "Cyprus": [
        {
            "id": 318,
            "name": "1. Division",
            "type": "League"
        }
    ],
    "Czech-Republic": [
        {
            "id": 345,
            "name": "Czech Liga",
            "type": "League"
        }
    ],
    "Denmark": [
        {
            "id": 119,
            "name": "Superliga",
            "type": "League"
        },
        {
            "id": 120,
            "name": "1. Division",
            "type": "League"
        }
    ],
    "Egypt": [
        {
            "id": 233,
            "name": "Premier League",
            "type": "League"
        }
    ],
    "England": [
        {
            "id": 39,
            "name": "Premier League",
            "type": "League"
        },
        {
            "id": 40,
            "name": "Championship",
            "type": "League"
        }
    ],
    "Estonia": [
        {
            "id": 329,
            "name": "Mestriliiga",
            "type": "League"
        }
    ],
    "France": [
        {
            "id": 61,
            "name": "Ligue 1",
            "type": "League"
        },
        {
            "id": 62,
            "name": "Ligue 2",
            "type": "League"
        }
    ],
    "Germany": [
        {
            "id": 78,
            "name": "Bundesliga",
            "type": "League"
        },
        {
            "id": 79,
            "name": "2. Bundesliga",
            "type": "League"
        }
    ],
    "Greece": [
        {
            "id": 197,
            "name": "Super League 1",
            "type": "League"
        }
    ],
    "Hungary": [
        {
            "id": 271,
            "name": "NB I",
            "type": "League"
        },
        {
            "id": 272,
            "name": "NB II",
            "type": "League"
        }
    ],
    "Italy": [
        {
            "id": 135,
            "name": "Serie A",
            "type": "League"
        },
        {
            "id": 136,
            "name": "Serie B",
            "type": "League"
        }
    ],
    "Japan": [
        {
            "id": 98,
            "name": "J1 League",
            "type": "League"
        }
    ],
    "Latvia": [
        {
            "id": 364,
            "name": "1. Liga",
            "type": "League"
        },
        {
            "id": 365,
            "name": "Virsliga",
            "type": "League"
        }
    ],
    "Lithuania": [
        {
            "id": 361,
            "name": "1 Lyga",
            "type": "League"
        },
        {
            "id": 362,
            "name": "A Lyga",
            "type": "League"
        }
    ],
    "Malta": [
        {
            "id": 393,
            "name": "Premier League",
            "type": "League"
        },
        {
            "id": 392,
            "name": "Challenge League",
            "type": "League"
        }
    ],
    "Mexico": [
        {
            "id": 262,
            "name": "Liga MX",
            "type": "League"
        }
    ],
    "Netherlands": [
        {
            "id": 88,
            "name": "Eredivisie",
            "type": "League"
        },
        {
            "id": 89,
            "name": "Eerste Divisie",
            "type": "League"
        }
    ],
    "Norway": [
        {
            "id": 103,
            "name": "Eliteserien",
            "type": "League"
        },
        {
            "id": 104,
            "name": "1. Division",
            "type": "League"
        }
    ],
    "Poland": [
        {
            "id": 106,
            "name": "Ekstraklasa",
            "type": "League"
        }
    ],
    "Portugal": [
        {
            "id": 94,
            "name": "Primeira Liga",
            "type": "League"
        },
        {
            "id": 95,
            "name": "Segunda Liga",
            "type": "League"
        }
    ],
    "Romania": [
        {
            "id": 283,
            "name": "Liga I",
            "type": "League"
        }
    ],
    "Saudi-Arabia": [
        {
            "id": 307,
            "name": "Pro League",
            "type": "League"
        }
    ],
    "Scotland": [
        {
            "id": 179,
            "name": "Premiership",
            "type": "League"
        }
    ],
    "Serbia": [
        {
            "id": 286,
            "name": "Super Liga",
            "type": "League"
        }
    ],
    "Slovakia": [
        {
            "id": 332,
            "name": "Super Liga",
            "type": "League"
        }
    ],
    "South-Africa": [
        {
            "id": 288,
            "name": "Premier Soccer League",
            "type": "League"
        }
    ],
    "Spain": [
        {
            "id": 140,
            "name": "La Liga",
            "type": "League"
        },
        {
            "id": 141,
            "name": "Segunda Divisi\u00f3n",
            "type": "League"
        }
    ],
    "Sweden": [
        {
            "id": 113,
            "name": "Allsvenskan",
            "type": "League"
        },
        {
            "id": 114,
            "name": "Superettan",
            "type": "League"
        }
    ],
    "Switzerland": [
        {
            "id": 207,
            "name": "Super League",
            "type": "League"
        },
        {
            "id": 208,
            "name": "Challenge League",
            "type": "League"
        }
    ],
    "Turkey": [
        {
            "id": 203,
            "name": "S\u00fcper Lig",
            "type": "League"
        }
    ],
    "USA": [
        {
            "id": 253,
            "name": "Major League Soccer",
            "type": "League"
        }
    ],
    "Wales": [
        {
            "id": 110,
            "name": "Premier League",
            "type": "League"
        }
    ],
    "World": [
        {
            "id": 2,
            "name": "UEFA Champions League",
            "type": "Cup"
        },
        {
            "id": 3,
            "name": "UEFA Europa League",
            "type": "Cup"
        },
        {
            "id": 848,
            "name": "UEFA Europa Conference League",
            "type": "Cup"
        }
    ]
};
