import React, { useState } from 'react';
import { 
    Box, 
    Typography, 
    Grid,
    Paper,
    Avatar,
    LinearProgress,
    Tooltip,
    Collapse,
    List,
    Rating
} from '@mui/material';

const InjuriesTab = ({ homeTeam, awayTeam }) => {
    const [expandedPlayer, setExpandedPlayer] = useState(null);

    if ((!homeTeam.injuries || homeTeam.injuries.length === 0) && 
        (!awayTeam.injuries || awayTeam.injuries.length === 0)) {
        return <Typography align="center" sx={{ py: 3 }}>No injury information available for this fixture.</Typography>;
    }

    // Calculate impact score based on player stats
    const calculateImpactScore = (player) => {
        if (!player.rating || player.rating === "0") return 0;
        
        // Calculate a weighted impact score
        const goalContribution = player.goal_involvement ? player.goal_involvement * 15 : 0;
        const minutes = player.minutes ? Math.min(player.minutes / 20, 50) : 0;
        const rating = player.rating ? parseFloat(player.rating) * 10 : 0;
        
        return Math.min(Math.round((goalContribution + minutes + rating) / 10), 10);
    };

    // Sort players by rating (highest first)
    const sortedHomePlayers = [...(homeTeam.injuries || [])].sort((a, b) => {
        const ratingA = a.rating ? parseFloat(a.rating) : 0;
        const ratingB = b.rating ? parseFloat(b.rating) : 0;
        return ratingB - ratingA;
    });

    const sortedAwayPlayers = [...(awayTeam.injuries || [])].sort((a, b) => {
        const ratingA = a.rating ? parseFloat(a.rating) : 0;
        const ratingB = b.rating ? parseFloat(b.rating) : 0;
        return ratingB - ratingA;
    });

    // Render a player list item
    const PlayerListItem = ({ player, teamColor, teamName }) => {
        const isExpanded = expandedPlayer === player.id;
        const hasPlayed = player.minutes > 0;
        const playerRating = player.rating ? parseFloat(player.rating) : 0;
        const impactScore = calculateImpactScore(player);
        
        return (
            <Box 
                sx={{ 
                    mb: 1,
                    borderRadius: '8px',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease',
                    boxShadow: isExpanded ? '0 4px 12px rgba(0,0,0,0.1)' : '0 2px 4px rgba(0,0,0,0.05)',
                    border: '1px solid',
                    borderColor: isExpanded ? teamColor : 'rgba(0,0,0,0.08)',
                    '&:hover': {
                        boxShadow: '0 6px 16px rgba(0,0,0,0.1)',
                        borderColor: teamColor,
                    },
                }}
                onClick={() => setExpandedPlayer(expandedPlayer === player.id ? null : player.id)}
            >
                {/* Player summary (always visible) */}
                <Paper 
                    elevation={0} 
                    sx={{ 
                        p: 1,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '8px 8px 0 0',
                        bgcolor: isExpanded ? `${teamColor}10` : 'white',
                    }}
                >
                    <Avatar 
                        src={player.photo} 
                        alt={player.name}
                        sx={{ 
                            width: 40, 
                            height: 40,
                            mr: 2,
                            border: '2px solid',
                            borderColor: `${teamColor}50`
                        }}
                    />
                    <Box sx={{ flex: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                                {player.name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                    {playerRating.toFixed(1)}
                                </Typography>
                                <Rating 
                                    value={playerRating / 2} 
                                    precision={0.5} 
                                    readOnly 
                                    size="small" 
                                    max={5}
                                    sx={{
                                        '& .MuiRating-iconFilled': {
                                            color: teamColor === '#2196f3' ? '#1976d2' : '#d32f2f',
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                            {player.position}
                        </Typography>
                    </Box>
                </Paper>

                {/* Expanded player details */}
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <Box sx={{ p: 2, bgcolor: 'white' }}>
                        {hasPlayed ? (
                            <>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body2" sx={{ mb: 0.5, display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Impact Rating:</span> <span>{impactScore}/10</span>
                                    </Typography>
                                    <LinearProgress 
                                        variant="determinate" 
                                        value={impactScore * 10} 
                                        sx={{ 
                                            height: 8, 
                                            borderRadius: 4,
                                            backgroundColor: 'rgba(0,0,0,0.05)',
                                            '& .MuiLinearProgress-bar': {
                                                borderRadius: 4,
                                                backgroundImage: `linear-gradient(90deg, 
                                                    ${impactScore < 4 ? '#ff9800' : 
                                                    impactScore < 7 ? '#4caf50' : 
                                                    teamColor} 0%, 
                                                    ${impactScore < 4 ? '#f57c00' : 
                                                    impactScore < 7 ? '#388e3c' : 
                                                    teamColor === '#2196f3' ? '#1976d2' : '#c62828'} 100%)`,
                                            }
                                        }}
                                    />
                                </Box>

                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={3}>
                                        <Tooltip title="Goals scored">
                                            <Box sx={{ p: 1, bgcolor: 'rgba(33, 150, 243, 0.08)', borderRadius: 1, textAlign: 'center' }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                    Goals
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#2196f3' }}>
                                                    {player.total_goals || 0}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="Assists provided">
                                            <Box sx={{ p: 1, bgcolor: 'rgba(156, 39, 176, 0.08)', borderRadius: 1, textAlign: 'center' }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                    Assists
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#9c27b0' }}>
                                                    {player.assists || 0}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="Minutes played">
                                            <Box sx={{ p: 1, bgcolor: 'rgba(0, 0, 0, 0.04)', borderRadius: 1, textAlign: 'center' }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                    Mins
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                                                    {player.minutes || 0}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="Total goal involvements (goals + assists)">
                                            <Box sx={{ p: 1, bgcolor: 'rgba(76, 175, 80, 0.08)', borderRadius: 1, textAlign: 'center' }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                                                    Inv.
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                                                    {player.goal_involvement || 0}
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                <Box sx={{ mb: 1 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Tooltip title="Key passes made">
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                                        Key Passes:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                                        {player.key_passes || 0}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Shots on target">
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                                        On Target:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                                        {player.shots_on_target || 0}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Dribbles completed / attempted">
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                                        Dribbles:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                                        {player.dribbles_successful || 0}/{player.dribbles_attempted || 0}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Tooltip title="Duels won / total duels">
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                                        Duels Won:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                                        {player.duels_won || 0}/{player.duels_total || 0}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        ) : (
                            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
                                No playing statistics available
                            </Typography>
                        )}
                    </Box>
                </Collapse>
            </Box>
        );
    };

    return (
        <Box sx={{ mt: 2 }}>
            {/* Team Names Header */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mb: 3,
                pb: 1
            }}>
                <Typography 
                    variant="h6" 
                    sx={{ 
                        fontSize: '1.5rem', 
                        fontWeight: 'bold', 
                        color: '#2c3e50'
                    }}
                >
                    {homeTeam.team_name}
                </Typography>
                
                <Typography 
                    variant="h6" 
                    sx={{ 
                        fontSize: '1.5rem', 
                        fontWeight: 'bold', 
                        color: '#2c3e50'
                    }}
                >
                    {awayTeam.team_name}
                </Typography>
            </Box>
            
            {/* Content in two columns */}
            <Grid container spacing={3}>
                {/* Home Team Column */}
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                mb: 2, 
                                fontWeight: 'bold', 
                                color: '#2196f3',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                pb: 1
                            }}
                        >
                            Injured Players ({sortedHomePlayers.length})
                        </Typography>
                        
                        {sortedHomePlayers.length > 0 ? (
                            <List sx={{ p: 0 }}>
                                {sortedHomePlayers.map((player, index) => (
                                    <PlayerListItem 
                                        key={player.id || index} 
                                        player={player} 
                                        teamColor="#2196f3"
                                        teamName={homeTeam.team_name}
                                    />
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body1" sx={{ textAlign: 'center', py: 2, fontStyle: 'italic' }}>
                                No injury information available
                            </Typography>
                        )}
                    </Box>
                </Grid>
                
                {/* Away Team Column */}
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                mb: 2, 
                                fontWeight: 'bold', 
                                color: '#f44336',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                pb: 1
                            }}
                        >
                            Injured Players ({sortedAwayPlayers.length})
                        </Typography>
                        
                        {sortedAwayPlayers.length > 0 ? (
                            <List sx={{ p: 0 }}>
                                {sortedAwayPlayers.map((player, index) => (
                                    <PlayerListItem 
                                        key={player.id || index} 
                                        player={player} 
                                        teamColor="#f44336"
                                        teamName={awayTeam.team_name}
                                    />
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body1" sx={{ textAlign: 'center', py: 2, fontStyle: 'italic' }}>
                                No injury information available
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default InjuriesTab;
