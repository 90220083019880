import React from 'react';
import { 
    Box, 
    Typography, 
    Grid, 
    LinearProgress 
} from '@mui/material';

const PredictionsTab = ({ predictions, homeTeam, awayTeam }) => {
    if (!predictions) {
        return <Typography>No predictions available</Typography>;
    }

    // Add defensive checks to handle potential undefined properties
    const renderSafely = (value, decimals = 1) => {
        return value !== undefined ? Number(value).toFixed(decimals) : "N/A";
    };
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}>
            {/* Top Section: Expected Goals & Most Likely Score */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: 'center' }}>
                {/* Expected Goals */}
                <Box sx={{ 
                    p: 3, 
                    borderRadius: '16px',
                    background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                    minWidth: 250,
                    flex: 1,
                    textAlign: 'center'
                }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#1976d2' }}>
                        Expected Goals
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                        <Box sx={{ textAlign: 'center', width: '30%' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2196f3' }}>
                                {renderSafely(predictions.expected_goals.home)}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {homeTeam}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', width: '30%' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#9c27b0' }}>
                                {renderSafely(predictions.expected_goals.total)}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Total
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', width: '30%' }}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#f44336' }}>
                                {renderSafely(predictions.expected_goals.away)}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {awayTeam}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Most Likely Score */}
                <Box sx={{ 
                    p: 3, 
                    borderRadius: '16px',
                    background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                    minWidth: 250,
                    flex: 1,
                    textAlign: 'center'
                }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#1976d2' }}>
                        Most Likely Score
                    </Typography>
                    <Typography 
                        variant="h3" 
                        sx={{ 
                            fontWeight: 'bold', 
                            color: '#1e88e5',
                            background: 'linear-gradient(90deg, #2196f3, #9c27b0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            mb: 1
                        }}
                    >
                        {predictions.most_likely_score.score}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                        Probability: {renderSafely(predictions.most_likely_score.probability)}%
                    </Typography>
                </Box>
            </Box>

            {/* Middle Section: Top Scores Prediction & Match Outcome */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                {/* Top Score Predictions */}
                <Box sx={{ 
                    p: 3, 
                    borderRadius: '16px',
                    background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                    flex: 1,
                    minWidth: 250
                }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#1976d2' }}>
                        Top Score Predictions
                    </Typography>
                    <Grid container spacing={2}>
                        {predictions.top_scores.map((score, index) => (
                            <Grid item xs={6} sm={4} key={index}>
                                <Box sx={{ 
                                    bgcolor: 'rgba(33, 150, 243, 0.08)',
                                    p: 1.5,
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }
                                }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        {score.score}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                                    {renderSafely(score.probability)}%
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Match Outcome */}
                <Box sx={{ 
                    p: 3, 
                    borderRadius: '16px',
                    background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                    flex: 1,
                    minWidth: 250,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#1976d2' }}>
                        Match Outcome
                    </Typography>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {/* Home Win Probability */}
                        <Box sx={{ mb: 1.5 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {homeTeam} Win
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                                    {renderSafely(predictions.match_outcome.home_win)}%
                                </Typography>
                            </Box>
                            <LinearProgress 
                                variant="determinate" 
                                value={predictions.match_outcome.home_win || 0} 
                                sx={{
                                    height: 8,
                                    borderRadius: 5,
                                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                    '& .MuiLinearProgress-bar': {
                                        borderRadius: 5,
                                        backgroundColor: '#4caf50',
                                    },
                                }}
                            />
                        </Box>

                        {/* Draw Probability */}
                        <Box sx={{ mb: 1.5 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    Draw
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#ff9800' }}>
                                    {renderSafely(predictions.match_outcome.draw)}%
                                </Typography>
                            </Box>
                            <LinearProgress 
                                variant="determinate" 
                                value={predictions.match_outcome.draw || 0} 
                                sx={{
                                    height: 8,
                                    borderRadius: 5,
                                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                    '& .MuiLinearProgress-bar': {
                                        borderRadius: 5,
                                        backgroundColor: '#ff9800',
                                    },
                                }}
                            />
                        </Box>

                        {/* Away Win Probability */}
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                    {awayTeam} Win
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#f44336' }}>
                                    {renderSafely(predictions.match_outcome.away_win)}%
                                </Typography>
                            </Box>
                            <LinearProgress 
                                variant="determinate" 
                                value={predictions.match_outcome.away_win || 0} 
                                sx={{
                                    height: 8,
                                    borderRadius: 5,
                                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                    '& .MuiLinearProgress-bar': {
                                        borderRadius: 5,
                                        backgroundColor: '#f44336',
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Bottom Section: Goals Probabilities & Odds */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                {/* Goals Probability Section */}
                <Box sx={{ 
                    p: 3, 
                    borderRadius: '16px',
                    background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                    flex: 1,
                    minWidth: 300
                }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#1976d2' }}>
                        Goals Probabilities
                    </Typography>

                    <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Over/Under</Typography>
                        <Grid container spacing={2}>
                            {Object.entries(predictions.goals.over).map(([key, value]) => (
                                <Grid item xs={6} sm={3} key={`over-${key}`}>
                                    <Box sx={{ 
                                        bgcolor: 'rgba(76, 175, 80, 0.08)',
                                        p: 1,
                                        borderRadius: '8px',
                                        textAlign: 'center'
                                    }}>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            Over {key}
                                        </Typography>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                                            {renderSafely(value)}%
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Both Teams To Score</Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-around', 
                            gap: 2 
                        }}>
                            <Box sx={{ 
                                bgcolor: 'rgba(76, 175, 80, 0.08)',
                                p: 2,
                                borderRadius: '8px',
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Yes
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                                    {renderSafely(predictions.goals.btts.yes)}%
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                bgcolor: 'rgba(244, 67, 54, 0.08)',
                                p: 2,
                                borderRadius: '8px',
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    No
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#f44336' }}>
                                    {renderSafely(predictions.goals.btts.no)}%
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Betting Odds Section */}
                <Box sx={{ 
                    p: 3, 
                    borderRadius: '16px',
                    background: 'linear-gradient(145deg, rgba(255, 255, 255, 0.9) 0%, rgba(245, 247, 250, 0.85) 100%)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                    flex: 1,
                    minWidth: 300
                }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: '#1976d2' }}>
                        Betting Odds
                    </Typography>

                    <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Match Outcome</Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            gap: 2 
                        }}>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                flex: 1
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {homeTeam} Win
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.match_outcome.home_win, 2)}
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                flex: 1
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Draw
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.match_outcome.draw, 2)}
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                flex: 1
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {awayTeam} Win
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.match_outcome.away_win, 2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Over/Under 2.5</Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-around', 
                            gap: 2 
                        }}>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Over 2.5
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.over_under["over_2.5"], 2)}
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Under 2.5
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.over_under["under_2.5"], 2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Both Teams To Score</Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-around', 
                            gap: 2 
                        }}>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Yes
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.btts.yes, 2)}
                                </Typography>
                            </Box>
                            <Box sx={{ 
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                                p: 1.5,
                                borderRadius: '8px',
                                textAlign: 'center',
                                width: '100%'
                            }}>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    No
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {renderSafely(predictions.odds.btts.no, 2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PredictionsTab;
